import { useState, forwardRef, Ref } from "react";
import { Icon } from "../Icon";
import styles from "./styles/PasswordField.module.css";

interface PasswordFieldProps {
  id: string;
  label: string;
  name: string;
  value: string;
  setValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete: string;
  toggleLabel: string;
  errMsg?: string;
  stylesClass: string;
}

export const PasswordField = forwardRef(
  (
    {
      id,
      label,
      name,
      value,
      setValue,
      autoComplete,
      toggleLabel,
      errMsg = "",
      stylesClass,
    }: PasswordFieldProps,
    ref: Ref<HTMLInputElement> | null
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => {
      setShowPassword((prevShowPwdValue) => !prevShowPwdValue);
    };
    return (
      <div className={stylesClass}>
        <label htmlFor={id}>{label}</label>
        <div className={styles.password_box}>
          <input
            id={id}
            type={showPassword ? "text" : "password"}
            name={name}
            value={value}
            onChange={setValue}
            aria-describedby={`err-${id}`}
            autoComplete={autoComplete}
            aria-invalid={errMsg !== ""}
            ref={ref}
          />
          <button
            type="button"
            className={styles.password_toggle}
            onClick={togglePassword}
            aria-pressed={showPassword}
          >
            <span className="visually-hidden">{toggleLabel}</span>
            <Icon name={showPassword ? "Eye" : "EyeSlash"} />
          </button>
        </div>
        {errMsg && <span id={`err-${id}`}>{errMsg}</span>}
      </div>
    );
  }
);
