// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

[role="tablist"] {
  padding-top: 0.625rem;
}

[role="tablist"] button {
  position: relative; /* for positioning selected tab border */
  border: none;
  padding: 0.625rem 0.5rem;
  font: inherit;
  letter-spacing: revert;
}

[role="tablist"] button[aria-selected="true"] {
  font-weight: 600;
}

[role="tablist"] button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

[role="tablist"] button[aria-selected="true"]::after {
  border-bottom: 0.125rem solid var(--tertiary);
}

[role="tablist"] button:focus-visible {
  outline: none;
  border: none;
  box-shadow: none;
}

[role="tablist"] button span {
  padding: 0.25rem;
}

[role="tablist"] button:focus-visible span,
[role="tabpanel"]:focus-visible {
  border-radius: 0.125rem;
  outline: 0.125rem solid #555;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tab/Tab.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB,EAAE,wCAAwC;EAC5D,YAAY;EACZ,wBAAwB;EACxB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,uBAAuB;EACvB,4BAA4B;AAC9B","sourcesContent":["section {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n\n[role=\"tablist\"] {\n  padding-top: 0.625rem;\n}\n\n[role=\"tablist\"] button {\n  position: relative; /* for positioning selected tab border */\n  border: none;\n  padding: 0.625rem 0.5rem;\n  font: inherit;\n  letter-spacing: revert;\n}\n\n[role=\"tablist\"] button[aria-selected=\"true\"] {\n  font-weight: 600;\n}\n\n[role=\"tablist\"] button::after {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n}\n\n[role=\"tablist\"] button[aria-selected=\"true\"]::after {\n  border-bottom: 0.125rem solid var(--tertiary);\n}\n\n[role=\"tablist\"] button:focus-visible {\n  outline: none;\n  border: none;\n  box-shadow: none;\n}\n\n[role=\"tablist\"] button span {\n  padding: 0.25rem;\n}\n\n[role=\"tablist\"] button:focus-visible span,\n[role=\"tabpanel\"]:focus-visible {\n  border-radius: 0.125rem;\n  outline: 0.125rem solid #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
