// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customToast_buttonStyle__iXTlC {
    height: 3rem;
    width: 7rem;
    font-size: 1.2rem;
    font-weight: 600 !important;
    background: var(--primary) !important;
    color: #fff !important;
    padding: 0.8rem;
    margin-top: -1.2rem;
}

.customToast_buttonStyle__iXTlC:hover {
    background-color: var(--secondary) !important;
    border: 2px solid #A16C00;
    color: #fff;
}

.customToast_cancel_icon_button__BJP0e {
    /* height: 0.4rem; */
    /* width: 0.4rem; */
    margin: 0.3rem 0 0 9rem;
    padding: 0.8rem;
    color: var(--primary) !important;
}

.customToast_cancel_icon_button__BJP0e:hover {
    border-color: transparent;
    background-color: transparent;
    color: var(--secondary) !important;
}

.customToast_cancel_icon_button__BJP0e:focus {
    border-color: transparent;
    background-color: transparent;
    color: #A16C00;
}

.customToast_errorheader__ownR4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 12rem;
}`, "",{"version":3,"sources":["webpack://./src/stylesheets/customToast.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,2BAA2B;IAC3B,qCAAqC;IACrC,sBAAsB;IACtB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,6CAA6C;IAC7C,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".buttonStyle {\n    height: 3rem;\n    width: 7rem;\n    font-size: 1.2rem;\n    font-weight: 600 !important;\n    background: var(--primary) !important;\n    color: #fff !important;\n    padding: 0.8rem;\n    margin-top: -1.2rem;\n}\n\n.buttonStyle:hover {\n    background-color: var(--secondary) !important;\n    border: 2px solid #A16C00;\n    color: #fff;\n}\n\n.cancel_icon_button {\n    /* height: 0.4rem; */\n    /* width: 0.4rem; */\n    margin: 0.3rem 0 0 9rem;\n    padding: 0.8rem;\n    color: var(--primary) !important;\n}\n\n.cancel_icon_button:hover {\n    border-color: transparent;\n    background-color: transparent;\n    color: var(--secondary) !important;\n}\n\n.cancel_icon_button:focus {\n    border-color: transparent;\n    background-color: transparent;\n    color: #A16C00;\n}\n\n.errorheader {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-evenly;\n    margin-left: 12rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonStyle": `customToast_buttonStyle__iXTlC`,
	"cancel_icon_button": `customToast_cancel_icon_button__BJP0e`,
	"errorheader": `customToast_errorheader__ownR4`
};
export default ___CSS_LOADER_EXPORT___;
