import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCustomAxios } from "../../api/axios";
import { CANCEL_CHECKOUT_ENDPOINT } from "../../api/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export const CancelCheckout = () => {
  const [searchParams] = useSearchParams();
  const { makeRequest } = useCustomAxios();

  useEffect(() => {
    makeRequest(
      [
        {
          url: CANCEL_CHECKOUT_ENDPOINT,
          method: "get",
          params: {
            session_id: searchParams.get("session_id"),
          },
        },
      ],
      () => {
        const returnUrl =
          searchParams.get("type") === "subscription"
            ? "/subscription-plans"
            : "/dashboard/topup-plans";
        // navigate won't work due to the complex routing setup
        window.location.href = returnUrl;
      },
      (error) => console.error(error)
    );
  }, []);

  return (
    // TODO: refactor to re-useable HTML component
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Cancelling Checkout...</Typography>
    </div>
  );
};
