import { useState } from "react";
import { KEY_AUDITS_ENDPOINT } from "../../../../../api/endpoints";
import { useCustomAxios } from "../../../../../api/axios";
import { Table } from "../../../../../components/Table";

const scanHistoryTableHeaders = [
  {
    label: "Scan ID",
    sortBy: false,
  },
  {
    label: "Date Created",
    sortBy: true,
  },
  {
    label: "Pages",
    sortBy: false,
  },
  {
    label: "Status",
    sortBy: true,
  },
];

interface ScanHistoryType {
  _id: { $oid: string };
  created_at: { $date: { $numberLong: number } };
  updated_at: { $date: { $numberLong: number } };
  num_pages: number;
  status: string;
}

export const ScanHistory = ({ keyValue }: { keyValue: string }) => {
  const { makeRequest } = useCustomAxios();
  const [scansData, setScansData] = useState({
    scans: [] as ScanHistoryType[],
    pages: 0,
  });

  const renderScanHistoryTableBody = () => {
    return (
      <tbody>
        {scansData.scans.map((scan) => {
          return (
            <tr key={scan._id.$oid}>
              <td>{scan._id.$oid}</td>
              <td>
                {new Date(
                  +scan.created_at.$date.$numberLong
                ).toLocaleDateString("en-US")}
              </td>
              <td>{scan.num_pages}</td>
              <td>
                <strong data-status={scan.status.toLowerCase()}>
                  {scan.status[0].toUpperCase() + scan.status.slice(1)}
                </strong>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const fetchScans = (
    currPage: number,
    sortHeader: string,
    sortOrder: string
  ) => {
    const sortParam = sortHeader === "Date Created" ? "created_at" : "status";
    const orderParam = sortOrder === "ascending" ? "asc" : "desc";
    makeRequest(
      [
        {
          url: KEY_AUDITS_ENDPOINT,
          method: "get",
          params: {
            api_key: keyValue,
            page: currPage,
            limit: 10,
            sort_by: sortParam,
            order: orderParam,
          },
        },
      ],
      (responseDataArr) => {
        const [scans, pages] = responseDataArr[0];
        setScansData({ scans, pages });
      },
      (error) => console.error(error)
    );
  };

  return (
    <Table
      title="Scan History"
      showTitle={false}
      headers={scanHistoryTableHeaders}
      initialSortHeader="Date Created"
      fetchTableData={fetchScans}
      tableDataLength={scansData.scans.length}
      totalPages={scansData.pages}
      renderTableBody={renderScanHistoryTableBody}
    />
  );
};
