// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubscriptionPlans_plans_container__sGZ2\\+ {
  background-color: var(--backgroundSecondary);
  border-radius: 0.625rem;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
}

.SubscriptionPlans_plans_container__sGZ2\\+ h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.SubscriptionPlans_instructions__00LhV {
  margin: 0 0.25rem;
  font-size: 1rem;
  text-align: center;
}

.SubscriptionPlans_plans__XeG95 {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

@media screen and (min-width: 23.4375em) {
  .SubscriptionPlans_plans_container__sGZ2\\+ h2 {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 37.5625em) {
  .SubscriptionPlans_plans__XeG95 {
    flex-direction: row;
    gap: 0.5rem;
  }
}

@media screen and (min-width: 48em) {
  .SubscriptionPlans_plans_container__sGZ2\\+ {
    padding: 1.25rem 1rem;
  }

  .SubscriptionPlans_plans__XeG95 {
    gap: 0.75rem;
  }
}

@media screen and (min-width: 75em) {
  .SubscriptionPlans_plans_container__sGZ2\\+ {
    padding: 1.25rem;
  }

  .SubscriptionPlans_plans_container__sGZ2\\+ h2 {
    font-size: 2rem;
  }

  .SubscriptionPlans_plans__XeG95 {
    gap: 1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/subscription-plans/SubscriptionPlans.module.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,uBAAuB;EACvB,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,WAAW;EACb;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,SAAS;EACX;AACF","sourcesContent":[".plans_container {\n  background-color: var(--backgroundSecondary);\n  border-radius: 0.625rem;\n  padding: 1rem 0.75rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 1.25rem;\n}\n\n.plans_container h2 {\n  font-size: 1.5rem;\n  font-weight: 600;\n}\n\n.instructions {\n  margin: 0 0.25rem;\n  font-size: 1rem;\n  text-align: center;\n}\n\n.plans {\n  display: flex;\n  flex-direction: column;\n  gap: 1.2rem;\n}\n\n@media screen and (min-width: 23.4375em) {\n  .plans_container h2 {\n    font-size: 1.75rem;\n  }\n}\n\n@media screen and (min-width: 37.5625em) {\n  .plans {\n    flex-direction: row;\n    gap: 0.5rem;\n  }\n}\n\n@media screen and (min-width: 48em) {\n  .plans_container {\n    padding: 1.25rem 1rem;\n  }\n\n  .plans {\n    gap: 0.75rem;\n  }\n}\n\n@media screen and (min-width: 75em) {\n  .plans_container {\n    padding: 1.25rem;\n  }\n\n  .plans_container h2 {\n    font-size: 2rem;\n  }\n\n  .plans {\n    gap: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plans_container": `SubscriptionPlans_plans_container__sGZ2+`,
	"instructions": `SubscriptionPlans_instructions__00LhV`,
	"plans": `SubscriptionPlans_plans__XeG95`
};
export default ___CSS_LOADER_EXPORT___;
