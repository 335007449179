type userType = "new" | "trial" | "lera";
const welcomeText = {
  new: "Welcome to your free trial of Lera API. Your account comes with 100 free credits. Create a key to try it today.",
  trial:
    "You are in a Trial plan with 100 credits. If you'd like to get a new key, you can buy one by activating the Create Key button.",
  lera: "If you'd like to get a new key, you can buy one by activating the Create Key button. You can also manage existing keys from the API Keys Overview table.",
};
export const getWelcomeText = (usertype?: string) => {
  return usertype ? welcomeText[usertype as userType] : "";
};

export const getUserName = (firstname?: string, lastname?: string) => {
  const userName =
    firstname &&
    lastname &&
    `${firstname[0].toUpperCase() + firstname.slice(1)} ${
      lastname[0].toUpperCase() + lastname.slice(1)
    }`;
  return userName ?? "";
};

export const keysTableHeaders = [
  {
    label: "API Keys",
    sortBy: false,
  },
  {
    label: "Name",
    sortBy: false,
  },
  {
    label: "Date Created",
    sortBy: true,
  },
  {
    label: "Status",
    sortBy: true,
  },
];