import { useState } from "react";
import { APIKeyType } from "../../../../utils/general";
import {
  CREATE_KEY_ENDPOINT,
  DELETE_KEY_ENDPOINT,
  CREATE_CHECKOUT_SESSION_ENDPOINT,
} from "../../../../api/endpoints";
import { useCustomAxios } from "../../../../api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { ErrorModal } from "../../../../components/Modals/errorModal";
import { Icon } from "../../../../components/Icon";
import styles from "./styles/SubscriptionCard.module.css";

interface SubscriptionCardProps {
  name: string;
  info: string;
  price: number;
  scan_limit: number;
  batch_size: number;
  features: string[];
}

export const SubscriptionCard = ({
  name,
  info,
  price,
  scan_limit,
  batch_size,
  features,
}: SubscriptionCardProps) => {
  const { isLoading, makeRequest } = useCustomAxios();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleCardActivate = () => {
    makeRequest(
      [
        {
          url: CREATE_KEY_ENDPOINT,
          method: "post",
          data: {
            key_status: "inactive",
            scan_limit: scan_limit,
            batch_size: batch_size,
            plan_name: name,
          },
        },
        {
          url: CREATE_CHECKOUT_SESSION_ENDPOINT,
          method: "post",
          updateQueryData: (prevResponseData) => {
            const newKey = prevResponseData as APIKeyType;
            return {
              plan_name: name,
              api_key: newKey.key,
            };
          },
        },
      ],
      (responseDataArr) => {
        const [newKey, checkoutData] = responseDataArr;
        sessionStorage.setItem("curr_key", newKey.key);
        window.location.href = checkoutData.url;
      },
      (error) => {
        const checkoutKey = JSON.parse(error.config.data)["api_key"];
        if (checkoutKey) {
          // error occured during checkout session creation
          // since checkout initiation failed, delete key created
          makeRequest(
            [
              {
                url: DELETE_KEY_ENDPOINT,
                method: "delete",
                data: {
                  api_key: checkoutKey,
                },
              },
            ],
            () =>
              setErrorMsg(
                "Oops! Something went wrong. Please try again later."
              ),
            (error) => console.error(error)
          );
        }
      },
      "isSequential"
    );
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.info_container}>
          <div>
            <h3 className={styles.info_heading}>{name}</h3>
            <p>{info}</p>
          </div>
          <p className={styles.pricing}>
            ${price}
            <span className="visually-hidden">per month</span>
            <span aria-hidden="true">/mo</span>
          </p>
          <button
            type="button"
            className={`button primary ${styles.plan}`}
            onClick={handleCardActivate}
          >
            {`Buy ${name}`}
          </button>
        </div>
        <div className={styles.features_container}>
          <h4 className={styles.features_heading}>What's included</h4>
          <ul className={styles.features_list}>
            {features.map((feature, index) => (
              <li key={index} className={styles.feature}>
                <Icon name="Check" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
          <Typography role="alert">Loading Checkout...</Typography>
        </Backdrop>
      )}
      {errorMsg && (
        <ErrorModal
          message={errorMsg}
          open={!!errorMsg}
          onClose={() => setErrorMsg(null)}
        />
      )}
    </>
  );
};
