import { Tab } from "../../../../components/Tab";
import { ScanHistory } from "./components/ScanHistory";
import { TransactionDetails } from "./components/TransactionDetails";
import styles from "./styles/KeyDetailsTab.module.css";

interface KeyDetailsTabProps {
  keyValue: string;
  isCheckoutSuccess: boolean;
}

export const KeyDetailsTab = ({
  keyValue,
  isCheckoutSuccess,
}: KeyDetailsTabProps) => {
  return (
    <div className={styles.tab_container}>
      <Tab
        tabList={[
          {
            header: "Scan History",
            content: <ScanHistory keyValue={keyValue} />,
          },
          {
            header: "Transaction Details",
            content: <TransactionDetails keyValue={keyValue} />,
          },
        ]}
        {...(isCheckoutSuccess && {
          initialSelectedTab: "Transaction Details",
        })}
      />
    </div>
  );
};
