// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditKeyDetail_edit_form__Puh3W {
  width: 100%;
  display: flex;
  gap: 0.5rem;
}

.EditKeyDetail_edit_form__Puh3W button {
  align-self: flex-end;
  border-radius: 0.25rem; /* for when button gets focus */
}

.EditKeyDetail_text_field__pHoYW {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
}

.EditKeyDetail_text_field__pHoYW label {
  width: max-content; /* prevents clicking whitespace beside label from activating input field */
}

.EditKeyDetail_text_field__pHoYW input {
  width: 100%;
  border: 0.0625rem solid var(--borderSecondary);
  border-radius: 0.5rem;
  padding: 0.125rem 0.25rem;
  color: var(--contentPrimary);
  outline: none;
}

.EditKeyDetail_edit__cXJ85 {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  border: none;
  padding: 0 0.25rem;
  font-weight: 500;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: revert;
}`, "",{"version":3,"sources":["webpack://./src/pages/key-details/components/EditKeyDetail/styles/EditKeyDetail.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,sBAAsB,EAAE,+BAA+B;AACzD;;AAEA;EACE,SAAa;EACb,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,kBAAkB,EAAE,0EAA0E;AAChG;;AAEA;EACE,WAAW;EACX,8CAA8C;EAC9C,qBAAqB;EACrB,yBAAyB;EACzB,4BAA4B;EAC5B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".edit_form {\n  width: 100%;\n  display: flex;\n  gap: 0.5rem;\n}\n\n.edit_form button {\n  align-self: flex-end;\n  border-radius: 0.25rem; /* for when button gets focus */\n}\n\n.text_field {\n  flex: 1 1 0px;\n  display: flex;\n  flex-direction: column;\n  gap: 0.125rem;\n}\n\n.text_field label {\n  width: max-content; /* prevents clicking whitespace beside label from activating input field */\n}\n\n.text_field input {\n  width: 100%;\n  border: 0.0625rem solid var(--borderSecondary);\n  border-radius: 0.5rem;\n  padding: 0.125rem 0.25rem;\n  color: var(--contentPrimary);\n  outline: none;\n}\n\n.edit {\n  display: flex;\n  align-items: center;\n  gap: 0.3125rem;\n  border: none;\n  padding: 0 0.25rem;\n  font-weight: 500;\n  font-family: inherit;\n  line-height: inherit;\n  letter-spacing: revert;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit_form": `EditKeyDetail_edit_form__Puh3W`,
	"text_field": `EditKeyDetail_text_field__pHoYW`,
	"edit": `EditKeyDetail_edit__cXJ85`
};
export default ___CSS_LOADER_EXPORT___;
