import { Link } from "react-router-dom";
import { RETRY_VERIFICATION_ENDPOINT } from "../../api/endpoints";
import { PageTitle } from "../../components/PageTitle";
import { EmailForm } from "../../components/EmailForm";
import logo from "../../assets/lera.png";
import styles from "./styles/ExpiredVerificationLink.module.css";

export const ExpiredVerificationLink = () => {
  return (
    <PageTitle title="Expired Verification Link - Lera API">
      <div className={styles.expired_verification_container}>
        <header>
          <Link to="/">
            <img src={logo} alt="Lera API" />
          </Link>
        </header>
        <main>
          <EmailForm
            title="Expired Verification Link"
            instruction="The verification link has expired. Please enter your email address to receive a new verification link."
            reqEndpoint={RETRY_VERIFICATION_ENDPOINT}
            notifierUrl="/activate-account/notification"
          />
        </main>
      </div>
    </PageTitle>
  );
};
