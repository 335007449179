import { useState, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { GET_KEY_DETAILS_ENDPOINT } from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { PageTitle } from "../../components/PageTitle";
import { AuthLayout } from "../../components/AuthLayout";
import { Breadcrumb } from "../../components/Breadcrumb";
import { RevokeKey } from "./components/RevokeKey";
import { EditKeyDetail } from "./components/EditKeyDetail";
import { KeyDetailsTab } from "./components/KeyDetailsTab";
import styles from "./styles/KeyDetails.module.css";

interface KeyDetailsType {
  key: string;
  key_name: string;
  key_status: string;
  callback_url: string;
  plan_name: string;
  scan_limit: number;
  expiration_date: string;
  user_id: string;
}

export const KeyDetails = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [keyDetails, setKeyDetails] = useState<KeyDetailsType | null>(null);
  // updates the status value to display as key status changes
  const [keyStatus, setKeyStatus] = useState("");

  const { makeRequest } = useCustomAxios();

  useEffect(() => {
    makeRequest(
      [
        {
          url: GET_KEY_DETAILS_ENDPOINT,
          method: "get",
          params: {
            api_key:
              location.state?.keyValue ?? sessionStorage.getItem("curr_key"),
          },
        },
      ],
      (responseDataArr) => {
        const keyData = responseDataArr[0] as KeyDetailsType;
        setKeyDetails(keyData);
        setKeyStatus(keyData.key_status);
        // keep key in storage for page reloads and checkout success implementation
        if (!sessionStorage.getItem("curr_key")) {
          sessionStorage.setItem("curr_key", keyData.key);
        }
      },
      (error) => console.error(error)
    );
    return () => {
      // remove key from storage on page component unmount
      sessionStorage.removeItem("curr_key");
    };
  }, []);

  return (
    <PageTitle title="Key Details - Lera API">
      <AuthLayout pageH1="Key Details">
        {keyDetails && (
          <>
            <div className={styles.breadcrumb}>
              <Breadcrumb
                Links={[
                  {
                    label: "Dashboard",
                    to: "/dashboard",
                  },
                  {
                    label: keyDetails.key.slice(-11, -1),
                    to: "/dashboard/key-details",
                    state: { keyValue: keyDetails.key },
                  },
                ]}
              />
            </div>
            <div className={styles.details_container}>
              <div className={styles.heading}>
                <h2>{keyDetails.key}</h2>
                <strong data-status={keyStatus}>
                  <span className="visually-hidden">Status:</span>
                  {keyStatus[0].toUpperCase() + keyStatus.slice(1)}
                </strong>
              </div>
              <div className={styles.details}>
                <div className={styles.key_details}>
                  {["callback_url", "key_name"].map((detail, index) => {
                    return (
                      <EditKeyDetail
                        key={index}
                        keyValue={keyDetails.key}
                        detailLabel={detail}
                        initialDetailValue={
                          keyDetails[detail as keyof KeyDetailsType]
                        }
                      />
                    );
                  })}
                  <RevokeKey
                    keyValue={keyDetails.key}
                    setKeyStatus={setKeyStatus}
                  />
                </div>
                <div className={styles.plan_details}>
                  <p>Plan: {keyDetails.plan_name}</p>
                  <p>
                    Billing Date:{" "}
                    {new Date(keyDetails.expiration_date).toLocaleDateString(
                      "en-US"
                    )}
                  </p>
                  <p>Current Scan Limit: {keyDetails.scan_limit}</p>
                  {/* <Link to="" className={`button primary ${styles.topup_link}`}>
                Add Credits
              </Link> */}
                </div>
              </div>
              <KeyDetailsTab
                keyValue={keyDetails.key}
                isCheckoutSuccess={searchParams.get("checkout") === "success"}
              />
            </div>
          </>
        )}
      </AuthLayout>
    </PageTitle>
  );
};
