// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorSummary_error_summary__nYlwF ul {
  margin: 0 0 1.5rem 1.5rem;
}

.ErrorSummary_error_summary__nYlwF p,
.ErrorSummary_error_summary__nYlwF li {
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorSummary/styles/ErrorSummary.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,qBAAqB;AACvB","sourcesContent":[".error_summary ul {\n  margin: 0 0 1.5rem 1.5rem;\n}\n\n.error_summary p,\n.error_summary li {\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_summary": `ErrorSummary_error_summary__nYlwF`
};
export default ___CSS_LOADER_EXPORT___;
