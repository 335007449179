// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar_container__x0pIC {
  padding: 2.5rem 2rem 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.Sidebar_logo__nz6-R {
  width: 2.75rem;
  height: 2.75rem;
}

.Sidebar_logo_link__G-KEx,
.Sidebar_logo_link__G-KEx img {
  display: block;
  width: 100%;
  height: 100%;
}

.Sidebar_main_nav__9azoI ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Sidebar_main_nav__9azoI a {
  --icon-stroke: #545775;
  border-radius: 0.5rem;
  text-decoration: none;
  color: inherit;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.Sidebar_main_nav__9azoI a,
.Sidebar_main_nav__9azoI a:focus-visible {
  padding: 0.5rem 1rem;
}

.Sidebar_main_nav__9azoI a[aria-current="page"] {
  background-color: #f5f5f7;
  font-weight: 700;
}

.Sidebar_main_nav__9azoI a[aria-current="page"],
.Sidebar_main_nav__9azoI a:hover {
  --icon-stroke: var(--secondary);
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;;EAEE,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,qBAAqB;EACrB,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;;EAEE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,+BAA+B;AACjC","sourcesContent":[".sidebar_container {\n  padding: 2.5rem 2rem 0;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n}\n\n.logo {\n  width: 2.75rem;\n  height: 2.75rem;\n}\n\n.logo_link,\n.logo_link img {\n  display: block;\n  width: 100%;\n  height: 100%;\n}\n\n.main_nav ul {\n  list-style-type: none;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.main_nav a {\n  --icon-stroke: #545775;\n  border-radius: 0.5rem;\n  text-decoration: none;\n  color: inherit;\n  font-weight: 500;\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.main_nav a,\n.main_nav a:focus-visible {\n  padding: 0.5rem 1rem;\n}\n\n.main_nav a[aria-current=\"page\"] {\n  background-color: #f5f5f7;\n  font-weight: 700;\n}\n\n.main_nav a[aria-current=\"page\"],\n.main_nav a:hover {\n  --icon-stroke: var(--secondary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar_container": `Sidebar_sidebar_container__x0pIC`,
	"logo": `Sidebar_logo__nz6-R`,
	"logo_link": `Sidebar_logo_link__G-KEx`,
	"main_nav": `Sidebar_main_nav__9azoI`
};
export default ___CSS_LOADER_EXPORT___;
