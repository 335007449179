// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailForm_email_form_container__JUiJs {
  max-width: 27rem;
}

.EmailForm_email_form_container__JUiJs h1 {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}

.EmailForm_email_form__vCLH3 {
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.3125rem rgba(99, 99, 99, 0.376);
  background-color: var(--backgroundSecondary);
  padding: 1.5rem 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.EmailForm_email_form__vCLH3 form {
  display: contents;
}

.EmailForm_email__8r3KA {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.EmailForm_email__8r3KA label {
  width: max-content; /* prevents clicking whitespace beside label from activating input field */
  font-weight: 600;
}

.EmailForm_email__8r3KA input {
  width: 100%;
  border: 0.0625rem solid var(--borderSecondary);
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: var(--contentPrimary);
  outline: none;
}

.EmailForm_email__8r3KA input[aria-invalid="true"] {
  border-color: var(--error);
}

.EmailForm_email__8r3KA span[id^="err-"] {
  color: var(--error);
}

@media screen and (min-width: 48em) {
  .EmailForm_email_form__vCLH3 {
    padding: 2rem 1.5rem;
    gap: 1.25rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/EmailForm/styles/EmailForm.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,wDAAwD;EACxD,4CAA4C;EAC5C,oBAAoB;EACpB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,kBAAkB,EAAE,0EAA0E;EAC9F,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,8CAA8C;EAC9C,qBAAqB;EACrB,eAAe;EACf,4BAA4B;EAC5B,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,oBAAoB;IACpB,YAAY;EACd;AACF","sourcesContent":[".email_form_container {\n  max-width: 27rem;\n}\n\n.email_form_container h1 {\n  font-weight: 700;\n  font-size: 1.5rem;\n  text-align: center;\n}\n\n.email_form {\n  margin-top: 1.5rem;\n  border-radius: 0.5rem;\n  box-shadow: 0 0.125rem 0.3125rem rgba(99, 99, 99, 0.376);\n  background-color: var(--backgroundSecondary);\n  padding: 1.5rem 1rem;\n  font-weight: 500;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.email_form form {\n  display: contents;\n}\n\n.email {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n}\n\n.email label {\n  width: max-content; /* prevents clicking whitespace beside label from activating input field */\n  font-weight: 600;\n}\n\n.email input {\n  width: 100%;\n  border: 0.0625rem solid var(--borderSecondary);\n  border-radius: 0.5rem;\n  padding: 0.5rem;\n  color: var(--contentPrimary);\n  outline: none;\n}\n\n.email input[aria-invalid=\"true\"] {\n  border-color: var(--error);\n}\n\n.email span[id^=\"err-\"] {\n  color: var(--error);\n}\n\n@media screen and (min-width: 48em) {\n  .email_form {\n    padding: 2rem 1.5rem;\n    gap: 1.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email_form_container": `EmailForm_email_form_container__JUiJs`,
	"email_form": `EmailForm_email_form__vCLH3`,
	"email": `EmailForm_email__8r3KA`
};
export default ___CSS_LOADER_EXPORT___;
