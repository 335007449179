// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordField_password_box__2JOKY {
  position: relative;
}

.PasswordField_password_toggle__RDYck {
  --password-btn-size: 2.5rem;
  width: var(--password-btn-size);
  height: var(--password-btn-size);
  padding: 0.25rem;
  color: var(--borderSecondary);
  position: absolute;
  top: calc(50% - calc(var(--password-btn-size) * 0.5));
  right: 0;
  z-index: 2;
}

.PasswordField_password_toggle__RDYck:hover,
.PasswordField_password_toggle__RDYck:focus-visible {
  border-radius: 0.625rem;
  background-color: transparent;
}

.PasswordField_password_toggle__RDYck svg {
  display: block;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/PasswordField/styles/PasswordField.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,+BAA+B;EAC/B,gCAAgC;EAChC,gBAAgB;EAChB,6BAA6B;EAC7B,kBAAkB;EAClB,qDAAqD;EACrD,QAAQ;EACR,UAAU;AACZ;;AAEA;;EAEE,uBAAuB;EACvB,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd","sourcesContent":[".password_box {\n  position: relative;\n}\n\n.password_toggle {\n  --password-btn-size: 2.5rem;\n  width: var(--password-btn-size);\n  height: var(--password-btn-size);\n  padding: 0.25rem;\n  color: var(--borderSecondary);\n  position: absolute;\n  top: calc(50% - calc(var(--password-btn-size) * 0.5));\n  right: 0;\n  z-index: 2;\n}\n\n.password_toggle:hover,\n.password_toggle:focus-visible {\n  border-radius: 0.625rem;\n  background-color: transparent;\n}\n\n.password_toggle svg {\n  display: block;\n  width: 100%;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password_box": `PasswordField_password_box__2JOKY`,
	"password_toggle": `PasswordField_password_toggle__RDYck`
};
export default ___CSS_LOADER_EXPORT___;
