import { Link } from "react-router-dom";
import { FORGOT_PASSWORD_ENDPOINT } from "../../api/endpoints";
import { PageTitle } from "../../components/PageTitle";
import { EmailForm } from "../../components/EmailForm";
import logo from "../../assets/lera.png";
import styles from "./styles/ForgotPassword.module.css";

export const ForgotPassword = () => {
  return (
    <PageTitle title="Forgot Password - Lera API">
      <div className={styles.forgot_password_container}>
        <header>
          <Link to="/">
            <img src={logo} alt="Lera API" />
          </Link>
        </header>
        <main>
          <EmailForm
            title="Forgot Password"
            instruction="Please enter your email address to receive a password reset link"
            reqEndpoint={FORGOT_PASSWORD_ENDPOINT}
            notifierUrl="/reset-password/link-notification"
          />
        </main>
      </div>
    </PageTitle>
  );
};
