import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import logo from "../../assets/lera.png";
import styles from "./styles/ResetPasswordLinkNotifier.module.css";

export const ResetPasswordLinkNotifier = () => {
  return (
    <PageTitle title="Reset Password Link Sent - Lera API">
      <div className={styles.reset_link_sent_container}>
        <header className={styles.reset_link_sent_header}>
          <Link to="/">
            <img src={logo} alt="Lera API" />
          </Link>
        </header>
        <main>
          <h1 className={styles.reset_link_sent_content_heading}>
            Reset Password Link Sent
          </h1>
          <div className={styles.reset_link_sent_textbox}>
            <p>
              A link has been sent to the email used to sign up. Please click on
              the link to reset your password.
            </p>
          </div>
        </main>
      </div>
    </PageTitle>
  );
};
