import axios from "axios";
import { useRef, useState, useEffect, useCallback } from "react";
import { noop } from "../utils/general";

const customAxios = axios.create({
  withCredentials: true,
});

interface QueryParams {
  url: string;
  method: "get" | "post" | "delete";
  data?: {};
  headers?: {};
  params?: {};
  updateQueryData?: (prevResponseData: unknown) => {};
}

const runQuery = async (
  { url, method, data = {}, headers = {}, params = {} }: QueryParams,
  controllerArr: AbortController[]
) => {
  const requestController = new AbortController();
  controllerArr.push(requestController);
  const response = await customAxios({
    url,
    method,
    data,
    headers,
    params,
    signal: requestController.signal,
  });
  // errors propagate outwards to error handler in makeRequest
  return response.data as unknown;
};

const runSequentialQueries = async (
  queries: QueryParams[],
  controllerArr: AbortController[]
) => {
  return queries.reduce(async (accPromise, query) => {
    const responseDataArr = await accPromise;
    const previousData = responseDataArr.at(-1);
    let currQuery = query;
    if (
      previousData &&
      currQuery.method === "post" &&
      currQuery?.updateQueryData
    ) {
      // use data from previous query in current query
      currQuery = {
        ...currQuery,
        data: currQuery.updateQueryData(previousData),
      };
    }
    const currData = await runQuery(currQuery, controllerArr);
    return [...responseDataArr, currData];
  }, Promise.resolve([] as unknown[]));
};

export const useCustomAxios = () => {
  const [isLoading, setIsLoading] = useState(false);
  const controllerRef = useRef<AbortController[]>([]);

  const makeRequest = useCallback(
    async (
      queries: QueryParams[],
      onSuccess: typeof noop,
      onError: typeof noop,
      isSequential?: "isSequential"
    ) => {
      setIsLoading(true);
      try {
        const responseDataArr = isSequential
          ? await runSequentialQueries(queries, controllerRef.current)
          : await Promise.all(
              queries.map((query) => runQuery(query, controllerRef.current))
            );
        onSuccess(responseDataArr);
      } catch (error) {
        // do nothing if query is aborted because component has unmounted
        if (!axios.isCancel(error)) {
          onError(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  useEffect(
    () => () => {
      controllerRef.current.forEach((controller) => controller.abort());
    },
    []
  );

  return { isLoading, makeRequest };
};
