import { Dispatch, SetStateAction } from "react";
import { REVOKE_KEY_ENDPOINT } from "../../../../api/endpoints";
import { useCustomAxios } from "../../../../api/axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import styles from "./styles/RevokeKey.module.css";

interface RevokeKeyProps {
  keyValue: string;
  setKeyStatus: Dispatch<SetStateAction<string>>;
}

export const RevokeKey = ({ keyValue, setKeyStatus }: RevokeKeyProps) => {
  const { isLoading, makeRequest } = useCustomAxios();
  const revokeKey = () => {
    makeRequest(
      [
        {
          url: REVOKE_KEY_ENDPOINT,
          method: "post",
          data: {
            api_key: keyValue,
          },
        },
      ],
      () => {
        setKeyStatus("revoked");
      },
      (error) => console.error(error)
    );
  };

  return isLoading ? (
    // TODO: refactor to HTML component
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Updating Key Status...</Typography>
    </Backdrop>
  ) : (
    <button
      type="button"
      className={`button secondary ${styles.revoke_key}`}
      onClick={revokeKey}
    >
      Revoke Key
    </button>
  );
};
