import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import styles from "../../stylesheets/customToast.module.css";
import { Icon } from "../Icon";

interface ErrorModalProps {
  message: string | React.ReactNode;
  open: boolean;
  onClose: () => void;
}


export const ErrorModal = ({ message, open, onClose }: ErrorModalProps) => {
  const style = {
    position: "absolute" as "absolute",
    display: "flex",
    flexDirection: "column",
    font: "Plus Jakarta Sans",
    alignItems: "center",
    justifyContent: "space-around",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        role="dialog"
        aria-labelledby="child-modal-title"
      >
        <Box sx={{ ...style, width: "20rem", height: "18rem" }}>
          <div className={styles.errorheader}>
            <></>
            <Button
              onClick={onClose}
              className={styles.cancel_icon_button}
              aria-label="Close"
            >
              <Icon name={`Close`} />
            </Button>
          </div>
          <h2
            id="child-modal-title"
            style={{
              fontSize: "2.4rem",
              marginTop: "-2rem",
              fontFamily: "Plus Jakarta Sans",
            }}
          >
            Error
          </h2>
          <p
            id="child-modal-description"
            style={{
              fontSize: "1rem",
              marginTop: "-2rem",
              fontFamily: "Plus Jakarta Sans",
              color: "#A12F2B",
            }}
          >
            {message}
          </p>
          <Button onClick={onClose} className={styles.buttonStyle}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};
