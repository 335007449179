import {
  useId,
  useState,
  useRef,
  useEffect,
  FormEvent,
  ChangeEvent,
} from "react";
import {
  GENERATE_TOKEN_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
} from "../../api/endpoints";
import { useCustomAxios } from "../../api/axios";
import { useAuth } from "../../context/AuthProvider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ErrorMessages from "../../components/ErrorMessages/errorMessage";
import { PasswordField } from "../../components/PasswordField";
import { SubmitButton } from "../../components/SubmitButton";

interface UpdatePasswordDataType {
  currPassword: string;
  newPassword: string;
}

interface UpdatePasswordProps {
  passwordFormStylesClass: string;
  passwordFieldStylesClass: string;
  passwordCtaStylesClass: string;
}

export const UpdatePassword = ({
  passwordFormStylesClass,
  passwordFieldStylesClass,
  passwordCtaStylesClass,
}: UpdatePasswordProps) => {
  const { makeRequest } = useCustomAxios();
  const { userData, getUserId } = useAuth();
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const id = useId();
  const updatePasswordDataIds = useRef<UpdatePasswordDataType>({
    currPassword: `curr-password-${id}`,
    newPassword: `new-password-${id}`,
  });
  const [formData, setFormData] = useState<UpdatePasswordDataType>({
    currPassword: "",
    newPassword: "",
  });
  const [formErrors, setFormErrors] = useState({} as UpdatePasswordDataType);
  const [formErrorsCount, setFormErrorsCount] = useState<number>(0);
  const errorsRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateLoginData = () => {
    let validationErrors = {} as UpdatePasswordDataType;
    let errorsCount = 0;

    if (formData.currPassword === "") {
      validationErrors.currPassword = "Current Password field cannot be empty";
      errorsCount++;
    }

    if (formData.newPassword === "") {
      validationErrors.newPassword = "New Password field cannot be empty";
      errorsCount++;
    }
    return { errorsCount, validationErrors };
  };

  // TODO: refactor error handling
  const handleServerError = (error: any) => {
    console.error(error);
  };

  const handlePasswordUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { errorsCount, validationErrors } = validateLoginData();
    if (errorsCount > 0) {
      setFormErrors(validationErrors);
      setFormErrorsCount(errorsCount);
    } else {
      setUpdatingPassword(true);
      makeRequest(
        [
          {
            url: GENERATE_TOKEN_ENDPOINT,
            method: "post",
            data: {
              email: userData!.email,
              user_id: getUserId(userData!),
              token_type: "password",
              updated_at: new Date(
                new Date().toISOString()
              ) /* to sync with MongoDB DateTime format */,
            },
          },
        ],
        (responseDataArr) => {
          const [token] = responseDataArr;
          makeRequest(
            [
              {
                url: UPDATE_PASSWORD_ENDPOINT,
                method: "post",
                data: {
                  token: token.token,
                  password: formData.newPassword,
                },
              },
            ],
            () => {
              setUpdatingPassword(false);
            },
            (error: any) => {
              setUpdatingPassword(false);
              handleServerError(error);
            }
          );
        },
        handleServerError
      );
    }
  };

  const handleCancelPasswordUpdate = () => {
    setFormData({
      currPassword: "",
      newPassword: "",
    });
    setFormErrors({} as UpdatePasswordDataType);
    setFormErrorsCount(0);
  };

  useEffect(() => {
    if (formErrorsCount > 0 && errorsRef.current) {
      errorsRef.current.focus();
    }
  }, [formErrorsCount]);

  return updatingPassword ? (
    // TODO: refactor to HTML component
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={updatingPassword}
    >
      <CircularProgress color="inherit" />
      <Typography role="alert">Updating...</Typography>
    </Backdrop>
  ) : (
    <div className={passwordFormStylesClass}>
      {formErrorsCount > 0 && (
        <ErrorMessages
          messages={formErrors}
          ids={updatePasswordDataIds.current}
          count={formErrorsCount}
          ref={errorsRef}
        />
      )}
      <form onSubmit={handlePasswordUpdate} noValidate>
        <PasswordField
          id={updatePasswordDataIds.current.currPassword}
          label="Current Password"
          name="currPassword"
          value={formData.currPassword}
          setValue={handleInputChange}
          autoComplete="current-password"
          toggleLabel="Show Current Password"
          errMsg={formErrors.currPassword ?? ""}
          stylesClass={passwordFieldStylesClass}
        />
        <PasswordField
          id={updatePasswordDataIds.current.newPassword}
          label="New Password"
          name="newPassword"
          value={formData.newPassword}
          setValue={handleInputChange}
          autoComplete="new-password"
          toggleLabel="Show New Password"
          errMsg={formErrors.newPassword ?? ""}
          stylesClass={passwordFieldStylesClass}
        />
        <div className={passwordCtaStylesClass}>
          <button
            type="button"
            className="secondary button"
            onClick={handleCancelPasswordUpdate}
          >
            Cancel
          </button>
          <SubmitButton label="Save Changes" />
        </div>
      </form>
    </div>
  );
};
