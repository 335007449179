// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_profile_container__\\+vOnR {
  border-radius: 0.625rem;
  background-color: var(--backgroundSecondary);
  padding: 0.5rem 1.5rem 2rem;
}

.Profile_profile_container__\\+vOnR form {
  display: contents;
}

.Profile_profile_content__MKX2U {
  width: 20rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Profile_input_box__Zk0Nw {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.Profile_input_box__Zk0Nw span:first-child,
.Profile_input_box__Zk0Nw label {
  width: max-content; /* prevents clicking whitespace beside label from activating input field */
  font-weight: 600;
}

.Profile_input_box__Zk0Nw span:last-child {
  align-self: flex-start;
}

.Profile_input_box__Zk0Nw span:last-child,
.Profile_input_box__Zk0Nw input {
  color: var(--contentPrimary);
  font-weight: 500;
}

.Profile_input_box__Zk0Nw input {
  width: 100%;
  border: 0.0625rem solid var(--borderSecondary);
  border-radius: 0.5rem;
  padding: 0.5rem;
  outline: none;
}

.Profile_input_box__Zk0Nw input[aria-invalid="true"] {
  border-color: var(--error);
}

.Profile_input_box__Zk0Nw span[id^="err-"] {
  color: var(--error);
}

.Profile_cta__Fct8Y {
  display: flex;
  gap: 1rem;
}

.Profile_cta__Fct8Y button {
  margin-top: 0; /* overrides submit btn styling */
  width: max-content;
  padding: 0.5rem 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/profile/Profile.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4CAA4C;EAC5C,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;;EAEE,kBAAkB,EAAE,0EAA0E;EAC9F,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;;EAEE,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,8CAA8C;EAC9C,qBAAqB;EACrB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa,EAAE,iCAAiC;EAChD,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":[".profile_container {\n  border-radius: 0.625rem;\n  background-color: var(--backgroundSecondary);\n  padding: 0.5rem 1.5rem 2rem;\n}\n\n.profile_container form {\n  display: contents;\n}\n\n.profile_content {\n  width: 20rem;\n  padding-top: 1.5rem;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n.input_box {\n  margin-bottom: 1rem;\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n}\n\n.input_box span:first-child,\n.input_box label {\n  width: max-content; /* prevents clicking whitespace beside label from activating input field */\n  font-weight: 600;\n}\n\n.input_box span:last-child {\n  align-self: flex-start;\n}\n\n.input_box span:last-child,\n.input_box input {\n  color: var(--contentPrimary);\n  font-weight: 500;\n}\n\n.input_box input {\n  width: 100%;\n  border: 0.0625rem solid var(--borderSecondary);\n  border-radius: 0.5rem;\n  padding: 0.5rem;\n  outline: none;\n}\n\n.input_box input[aria-invalid=\"true\"] {\n  border-color: var(--error);\n}\n\n.input_box span[id^=\"err-\"] {\n  color: var(--error);\n}\n\n.cta {\n  display: flex;\n  gap: 1rem;\n}\n\n.cta button {\n  margin-top: 0; /* overrides submit btn styling */\n  width: max-content;\n  padding: 0.5rem 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile_container": `Profile_profile_container__+vOnR`,
	"profile_content": `Profile_profile_content__MKX2U`,
	"input_box": `Profile_input_box__Zk0Nw`,
	"cta": `Profile_cta__Fct8Y`
};
export default ___CSS_LOADER_EXPORT___;
